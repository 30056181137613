import React from "react"

export default () => {
    return (
        <div className={`text-serif`}>
            <h1>Header 1</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <h4>Header 4</h4>
            <h5>Header 5</h5>
            <h6>Header 6</h6>
            <p>Paragraph text</p>
            <ul>
                <li>Unordered list item</li>
                <li>Another list item</li>
                <li>Blah blah blah</li>
                <ul>
                    <li>Nested list element</li>
                    <li>Yet another...</li>
                </ul>
            </ul>
            <ol>
                <li>Ordered list item</li>
                <li>Another list item</li>
                <li>Blah blah blah</li>
                <ol>
                    <li>Nested list element</li>
                    <li>Yet another...</li>
                </ol>
            </ol>
        </div>
    )
}